import React, { useEffect, useState, useRef } from "react";
import './App.css';
import useWindowSize from './component/useWindowSize';
import Page from "./component/Page";
import styled from "styled-components";
import logo from "./LogoPkindev.svg";
import logoFaceBook from "./LogoFacebook.svg";
import LogoLine from "./LogoLine.svg";
import LogoEmail from "./LogoEmail.svg";
import LogoPhone from "./LogoPhone.svg";

const Card = styled.span`
padding: 20px;
background: #a9a9a9d4;
border-radius: 20px;
font-size: larger;
`;
const ModalCopy = ({ text }) => {
  return <div style={{
    position: "absolute",
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  }}>
    <Card>{text}</Card>
  </div>
}

const App = () => {

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const size = useWindowSize();

  const [copy, setCopy] = useState("");

  const childRef = useRef();
  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy("");
      }, 1000);
    }
  }, [copy]);
  useEffect(() => {
    if (size.height) {
      let w = size.height / 1.7
      if (w > size.width) {
        let h = 1.7 * size.width;
        setHeight(h);
        setWidth(size.width);
      } else {
        setHeight(size.height - 190);
        setWidth(w);
      }
    } else {
      setWidth(0)
      setHeight(0)
    }
  }, [size.height, size.width]);

  return <>
    <div style={{ width: "100%", fontSize: "small" }}>
      <a href="https://www.pkindev.com">
        <img src={logo} height={80} style={{ marginTop: 20, marginLeft: 20 }} alt="logo" />
      </a>
    </div>
    <div style={{
      display: "flex", justifyContent: "center", position: "relative",
      width: "100%"
    }}>
      {height > 0 && width > 0 ? <Page height={height} width={width} ref={childRef} /> : null}

    </div>
    <div style={{ width: "auto", fontSize: "small", textAlign: "center" }}>
      <a href="https://www.facebook.com/pkindev">
        <img src={logoFaceBook} height={40} style={{ margin: "0 5px" }} alt="logoface" />
      </a>
      <a href="https://page.line.me/885yfwmc?openQrModal=true">
        <img src={LogoLine} height={40} style={{ margin: "0 5px" }} alt="logoline" />
      </a>
      <img src={LogoEmail}
        onClick={() => {
          navigator.clipboard.writeText("mongkontep@pkindev.com");
          setCopy("Copied this email.");
        }}
        height={40} style={{ margin: "0 5px", cursor: "pointer" }} alt="logoemail" />
      <img src={LogoPhone}
        onClick={() => {
          navigator.clipboard.writeText("(+66)93 696 4498");
          setCopy("Copied this telephone number.");
        }}
        height={40} style={{ margin: "0 5px", cursor: "pointer" }} alt="logophone" />
      <div style={{ color: "#14476E", fontSize: "small" }}>
        Copyright 2022 © Phuket Innovative Development Co., Ltd.
      </div>
    </div>
    {copy ? <ModalCopy text={copy} /> : null}
  </>
}

export default App;
