import React, { useEffect, useState, useRef, Suspense, lazy, useImperativeHandle } from 'react';
import useSound from 'use-sound';
import boopSfx from './SoundEffect.mp3';
import loading from "./loading-ci-gradient.gif";
const HTMLFlipBook = lazy(() => import("react-pageflip"));
const Silde = React.forwardRef((props, ref) => {
    return (
        <div className="page2 stf__item --soft" ref={ref} data-density="soft">
            <img src={props.pic} alt={`pic${props.number}`} style={{ width: "100%", height: "100%" }} />
        </div>
    );
});
const Page = React.forwardRef(({ width, height }, ref) => {
    const [page, setPage] = useState(0);
    const [play] = useSound(boopSfx);
    let flipBook = useRef();
    const dataPage = new Array(46).fill(0);
    useImperativeHandle(
        ref,
        () =>
        ({
            prevButtonClick,
            nextButtonClick
        })
    );
    const prevButtonClick = () => {
        flipBook.pageFlip().flipPrev();
    };
    const nextButtonClick = () => {
        flipBook.pageFlip().flipNext();
    };
    const onPage = (e) => {
        play();
        setPage(e.data);
    };
    const onChangeState = (e) => {
        // console.log("E : ", e);
    }
    useEffect(() => {
        play();
    }, [page, play])
    return <Suspense fallback={<div>
        <img src={loading} height={100} alt='loading' />
    </div>}>
        <HTMLFlipBook
            width={width}
            height={height}
            minWidth={300}
            maxWidth={width}
            // minHeight={height}
            maxHeight={height}
            size="stretch"
            // size="fixed"
            drawShadow={false}
            // maxShadowOpacity={0.5}
            showCover={true}
            mobileScrollSupport={true}
            onFlip={onPage}
            autoSize={true}
            onChangeState={onChangeState}
            // renderOnlyPageLengthChange={true}
            className="demo-book stf__parent"
            style={{
                maxHeight: height,
                maxWidth: width
            }}
            ref={el => flipBook = el}
        >
            {dataPage && dataPage.map((el, i) =>
                <Silde key={el + i} number={el + i} pic={`/assets/${i + 1}.jpg`} />
            )}
        </HTMLFlipBook>
    </Suspense>
});

export default Page;